<template>
  <div class="auth-header">
    <div class="container">
      <div class="row">
        <div class="col-md-3 col-12 offset-md-1 order-1 order-md-0 text-center text-md-left">
          <router-link
            class="action-label"
            :to="{ name: 'Landing' }"
          >
            Back to website
          </router-link>
        </div>
        <div class="col-md-4 col-12 order-0 order-md-1">
          <div class="auth-header__logo">
            <logo-svg />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LogoSvg from '@/components/svg/LogoSvg.vue';

export default {
  name: 'LandingHeader',
  components: {
    LogoSvg,
  },
};
</script>
