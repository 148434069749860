<template>
  <div class="layout auth-layout">
    <auth-header />

    <div class="layout-content">
      <router-view />
    </div>
  </div>
</template>

<script>
import AuthHeader from '@/components/auth/AuthHeader.vue';

export default {
  components: {
    AuthHeader,
  },
};
</script>

<style lang="scss">
@import "src/assets/sass/views/auth/auth";
</style>
